// tslint:disable:deprecation
// tslint:disable: no-string-literal
// tslint:disable: no-shadowed-variable
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_SERVICE_TIMEOUT } from '@constants/business.constant';
import { AuthorizationProvider } from '@providers/authorization/authorization';
import { Observable, of, throwError } from 'rxjs';
import { delay, timeout } from 'rxjs/operators';
import { IHttpParams, IMockup } from 'src/app/common/interfaces/http.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private httpSuccessCode = 200;
  private httpFailureCode = 400;
  private headers = new HttpHeaders();
  private params = new HttpParams();

  constructor(
    private authorizationProvider: AuthorizationProvider,
    private http: HttpClient
  ) {
    this.authorizationProvider.getAuthorizationRequestIntercepted().subscribe((session) => {
      if (!session || !session.sessionActive) {
        sessionStorage.removeItem('authorization');
      } else {
        sessionStorage.setItem('authorization', session.token);
      }
    });
  }

  private mockedResponse(mockup: IMockup): Observable<any> {
    const responseStatus = (mockup.failures && (Math.random() > environment.MOCK_SUCCESS_RATE)) ? 'failures' : 'success';
    const responseOptionsParams = { status: responseStatus === 'success' ? this.httpSuccessCode : this.httpFailureCode };

    if (responseStatus === 'success') { responseOptionsParams['body'] = mockup[responseStatus]; }
    else { responseOptionsParams['error'] = mockup[responseStatus]; }

    const response = new HttpResponse(responseOptionsParams);
    const responseError = new HttpErrorResponse(responseOptionsParams);
    return responseStatus === 'success' ? of(response.body).pipe(delay(environment.MOCK_DELAY_MILLISECONDS))
      : throwError(responseError.error);
  }

  private setAuthorizationToCustomHeaders(customHeaders: HttpHeaders): HttpHeaders {
    if (!sessionStorage.getItem('authorization')) {
      return customHeaders;
    }
    return customHeaders.set('authorization', sessionStorage.getItem('authorization'));
  }

  private httpCall({ method, url, mockup, data, customHeaders, timeoutValue, customParams }: IHttpParams): Observable<any> {
    const header = customHeaders ? this.setAuthorizationToCustomHeaders(customHeaders) : this.headers;
    const params = customParams ? customParams : this.params;
    timeoutValue = timeoutValue ? timeoutValue : DEFAULT_SERVICE_TIMEOUT;
    if (environment.USING_MOCKS) { return this.mockedResponse(mockup); }
    if (method === 'delete' || method === 'get') {
      return this.http[method]<any>(url, { headers: header, params }).pipe(timeout(timeoutValue));
    }
    return this.http[method]<any>(url, data, { headers: header }).pipe(timeout(timeoutValue));
  }

  public delete(url: string, mockup: IMockup, customHeaders?: HttpHeaders, timeoutValue?: number): Observable<any> {
    return this.httpCall({ method: 'delete', url, mockup, data: false, customHeaders, timeoutValue });
  }

  public get(url: string, mockup: IMockup, customParams?: HttpParams, customHeaders?: HttpHeaders, timeoutValue?: number): Observable<any> {
    return this.httpCall({ method: 'get', url, customParams, mockup, data: false, customHeaders, timeoutValue });
  }

  public post(url: string, data: any, mockup: IMockup, customHeaders?: HttpHeaders, timeoutValue?: number): Observable<any> {
    return this.httpCall({ method: 'post', url, mockup, data, customHeaders, timeoutValue });
  }

  public put(url: string, data: any, mockup: IMockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'put', url, mockup, data, customHeaders });
  }

  public patch(url: string, data: any, mockup: IMockup, timeoutSetting?: number, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'patch', url, mockup, data, timeoutValue: timeoutSetting, customHeaders });
  }

  public getTokenRedirect(): string {
    return sessionStorage.getItem('authorization');
  }
}
